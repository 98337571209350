import moment from 'moment-timezone';

export function formatTime(timestamp) {
    let result;
    const date = new Date(timestamp);

    if (moment().isSame(moment(date), 'day')) {
        result = moment(date).format('h:mm A');
    } else if (moment().diff(moment(date), 'days') == 1) {
        result = 'Yesterday';
    } else if (moment().isSame(moment(date), 'year')) {
        result = moment(date).format('MMM DD');
    } else {
        result = moment(date).format('DD/MM/YYYY');
    }

    return result;
}

export function formatName({ firstName, lastName }) {
    const formattedLastName = lastName ? lastName[0].toUpperCase() + '.' : '';
    return `${firstName} ${formattedLastName}`;
}

export function sortByTimestamp(a, b) {
    return new Date(b).valueOf() - new Date(a).valueOf();
}

export function getISOWithoutTimezone(date) {
    if (!date) {
        return null;
    }
    const formatted = date.getTime() - date.getTimezoneOffset() * 1000 * 60;
    return new Date(formatted).toISOString();
}

export function getAge(birthday) {
    if (birthday) {
        const date = new Date(birthday);
        return moment.duration(moment().diff(moment(date), 'hours'), 'hours').humanize();
    }
    return null;
}

export function floatHourToString(value) {
    const h = Math.trunc(value);
    const m = (value - h) * 60;
    return `${String(h === 0 ? 12 : h > 12 ? h - 12 : h)}:${String(m).padStart(2, '0')} ${h < 12 ? 'am' : 'pm'}`;
}

export function lengthToString(length) {
    if (length === undefined || length == null) {
        return '';
    }
    return length < 1 ? '30 minutes' : `${length} hour${length > 1 ? 's' : ''}`;
}

export function changeTimezone(date, ianatz) {
    // suppose the date is 12:00 UTC
    var invdate = new Date(
        date.toLocaleString('en-US', {
            timeZone: ianatz,
        })
    );

    // then invdate will be 07:00 in Toronto
    // and the diff is 5 hours
    var diff = date.getTime() - invdate.getTime();

    // so 12:00 in Toronto is 17:00 UTC
    return new Date(date.getTime() - diff); // needs to substract
}
