export const IMG_RESIZES = {
    x032: '0x32',
    x064: '0x64',
    x0128: '0x128',
    x0256: '0x256',
    x0354: '0x450',
    x0450: '0x450',
    x0512: '0x512',
    x0675: '0x512',
    x0768: '0x1024',
    x01024: '0x1024',
    x01400: '0x1600',
    x01536: '0x1600',
    x01600: '0x1600',
    x02048: '0x2048',
    x02400: '0x2048',
    x0: '0x0',
    x32: '32x32',
    x64: '64x64',
    x128: '128x128',
    x256: '256x256',
    x354: '354x256',
    x450: '450x450',
    x512: '512x512',
    x675: '675x450',
    x768: '768x512',
    x1024: '1024x1024',
    x1536: '1536x1024',
    x1400: '1400x2800',
    x1600: '1600x1600',
    x2400: '2400x1600',
    x2048: '2048x2048',
    x3072: '3072x2048',
};
