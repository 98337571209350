import React from 'react';
import PropTypes from 'prop-types';
import bemCn from 'bem-cn';
import './style.scss';

const b = bemCn('sniff-button');

const SniffButton = ({ children, component, block = false, size = 'md', color = 'primary', className = '', ...rest }) => {
    const ToRender = component ? component : 'button';
    return (
        <ToRender className={b({ size, color, block }) + ' ' + className} {...rest}>
            {children}
        </ToRender>
    );
};

SniffButton.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'secondary-dashed', 'danger', 'transparent']),
    size: PropTypes.oneOf(['lg', 'md', 'sm']),
    block: PropTypes.oneOf([true, 'mobile', 'desktop']),
};

export default SniffButton;
